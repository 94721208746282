<template>
    <el-scrollbar class="scoreList">
        <div class="box" v-if="role != 2">
            <div class="scoreList-header">
                <div class="header-left">
                    <div class="origin_rd">
                        <span>所属竞赛：</span>
                        <el-select v-model="selectClassValue" placeholder="请选择" @change="selectClass">
                            <el-option :key="0" :value="0" label="全部"></el-option>
                            <el-option
                                    v-for="item in classOptions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="button-space">
                        <el-button type="primary" size="mini" @click="refreshTotalScore">重新计算学生实操总分</el-button>
                    </div>
                    <div class="button-space">
                        <el-button size="mini" @click="refreshTheoryScore">重新计算学生理论总分</el-button>
                    </div>
                    <div class="button-space">
                        <el-button type="success" size="mini" @click="exportTotalScore">导出学生成绩表格</el-button>
                    </div>
                </div>
                <div class="header-right">
                    <span>数据更新时间：{{updateTime}}</span>
                    <i @click="refreshPage" style="cursor:pointer" class="iconfont">&#xe674;</i>
                </div>
            </div>
            <el-table class="roleNot2" border :default-sort="{prop: 'rank', order: 'ascending'}" :data="tableData"
                      style="width: 100%;flex: 1;margin-top: 20px;height: calc(100vh - 300px);overflow-y: auto"
                      :cell-style="{height: '60px',color:'#343442', fontSize:'14px',fontFamily:'PingFang SC',fontWeight:400}"
                      :header-cell-style="{fontWeight: '500', color: '#14141C', background: '#F3F4FA',height: '60px'}"
                      @sort-change="sorttTotalScore"
            >
                <el-table-column prop="rank" label="排行" align="center" width="100">
                    <template slot-scope="scope">
                        <span :class="scope.row.rank === 1 ? 'rankImg' : scope.row.rank === 2 ? 'rankImgTwo' : scope.row.rank === 3 ? 'rankImgThree' : ''"
                              style="font-family:Impact;font-style:italic;display: inline-block;width: 30px;">{{scope.row.rank}}.</span>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="学生" width="">
                    <template slot-scope="scope">
                        <div class="iconName" style="display: flex; align-items: center">
                            <img v-if="scope.row.headimgurl.url" class="img1"
                                 style="width: 40px;height: 40px;border-radius: 50%;" :src="scope.row.headimgurl.src"
                                 alt="">
                            <img v-else class="img2" style="width: 40px;height: 40px;border-radius: 50%;"
                                 :src="require('./../../assets/images/user_img.png')" alt="">
                            <span style="margin-left: 10px">{{scope.row.name }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="zx_score" :label="dianPu ? '店铺装修('+dianPu+'%'+')' : '店铺装修'">
                    <template slot-scope="scope">
                        {{Number(scope.row.zx_score).toFixed(2) || 0}}
                    </template>
                </el-table-column>
                <el-table-column prop="kefu_score" :label="keHu ? '客服服务('+keHu+'%'+')' : '客服服务'">
                    <template slot-scope="scope">
                        {{Number(scope.row.kefu_score).toFixed(2) || 0}}
                    </template>
                </el-table-column>
                <el-table-column prop="data_score" :label="yunYing ? '运营推广('+yunYing+'%'+')' : '运营推广'">
                    <template slot-scope="scope">
                        {{Number(scope.row.data_score).toFixed(2) || 0}}
                    </template>
                </el-table-column>
                <el-table-column prop="analysis_score" :label="fenXi ? '分析答辩('+fenXi+'%'+')' : '分析答辩'">
                    <template slot-scope="scope">
                        {{Number(scope.row.analysis_score).toFixed(2) || 0}}
                    </template>
                </el-table-column>
                <el-table-column prop="topic_score" :label="topic ? '理论考试('+topic+'%'+')' : '理论考试'">
                    <template slot-scope="scope">
                        {{Number(scope.row.topic_score).toFixed(2) || 0}}
                    </template>
                </el-table-column>
                <el-table-column prop="new_kefu_score" :label="newKeFu ? '新客服服务('+newKeFu+'%'+')' : '新客服服务'">
                    <template slot-scope="scope">
                        {{Number(scope.row.new_kefu_score).toFixed(2) || 0}}
                    </template>
                </el-table-column>
                <el-table-column prop="customer_data_analysis_score" :label="dataAnalysis ? '数据分析('+dataAnalysis+'%'+')' : '数据分析'">
                    <template slot-scope="scope">
                        {{Number(scope.row.customer_data_analysis_score).toFixed(2) || 0}}
                    </template>
                </el-table-column>
                <el-table-column prop="total_score" label="合计分数" sortable="custom">
                    <template slot-scope="scope">
                        {{Number(scope.row.total_score).toFixed(2) || 0}}
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <!--                        <i style="cursor:pointer" @click="toDetail" class="iconfont operation-detail">&#xe60d;</i>-->
                        <el-button class="look-detail" type="text" @click="toDetail(scope.row)" style="">查看详情
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    class="pages-center"
                    :current-page="adminPages.currentPageNum"
                    :page-size="adminPages.eachPageNum"
                    layout="prev, pager, next, jumper"
                    :total="adminPages.total"
                    @current-change="adminCurrentChange"
            ></el-pagination>
        </div>
        <div class="box" v-else>
            <div class="scoreList-header">
                <div class="header-left">
                    <span>所属训练：</span>
                    <el-select v-model="selectClassValue2" placeholder="请选择" @change="selectClass2">
                        <el-option :key="0" :value="0" label="全部"></el-option>
                        <el-option
                                v-for="item in classOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="header-right">
                    <span>数据更新时间：{{updateTime}}</span>
                    <i @click="refreshPage" style="cursor:pointer" class="iconfont">&#xe674;</i>
                </div>
            </div>
            <div class="xlTable">
                <div class="xlTableHeader">
                    <div class="xlTableHeaderItem" style="display: flex;"><span style="margin-left: 30px">学生名称</span>
                    </div>
                    <div class="xlTableHeaderItem">训练管理</div>
                    <div class="xlTableHeaderItem" style="display: flex;justify-content: center"><span>客服服务{{kfPercent ? '('+kfPercent+'%'+')' : ''}}</span>
                        <div style="display: inline-block;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                            <i @click="kfSortMaxToMin"
                               style="cursor: pointer;font-weight: bold;display: inline-block;transform:rotate(180deg);line-height: 1;font-size: 12px"
                               :class="iconfontSortValue === 1 ? 'iconfont iconfontSort' : 'iconfont'">&#xe603;</i>
                            <i @click="kfSortMinToMax"
                               style="cursor: pointer;font-weight: bold;display:inline-block;line-height: 1;font-size: 12px"
                               :class="iconfontSortValue === 2 ? 'iconfont iconfontSort' : 'iconfont'">&#xe603;</i>
                        </div>

                    </div>
                    <div class="xlTableHeaderItem" style="display: flex;justify-content: center">店铺营销{{marketingPercent
                        ? '('+marketingPercent+'%'+')' : ''}}
                        <div style="display: inline-block;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                            <i @click="marketingSortMinToMax"
                               style="cursor: pointer;font-weight: bold;display: inline-block;transform:rotate(180deg);line-height: 1;font-size: 12px"
                               :class="iconfontSortValue2 === 1? 'iconfont iconfontSort' : 'iconfont'">&#xe603;</i>
                            <i @click="marketingSortMaxToMin"
                               style="cursor: pointer;font-weight: bold;display:inline-block;line-height: 1;font-size: 12px"
                               :class="iconfontSortValue2 === 2? 'iconfont iconfontSort' : 'iconfont'">&#xe603;</i>
                        </div>
                    </div>
                    <div class="xlTableHeaderItem" style="display: flex;justify-content: center">新客服服务{{kfNPercent
                        ? '('+kfNPercent+'%'+')' : ''}}
                        <div style="display: inline-block;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                            <i @click="newKFSortMinToMax"
                               style="cursor: pointer;font-weight: bold;display: inline-block;transform:rotate(180deg);line-height: 1;font-size: 12px"
                               :class="iconfontSortValue3 === 1? 'iconfont iconfontSort' : 'iconfont'">&#xe603;</i>
                            <i @click="newKFSortMaxToMin"
                               style="cursor: pointer;font-weight: bold;display:inline-block;line-height: 1;font-size: 12px"
                               :class="iconfontSortValue3 === 2? 'iconfont iconfontSort' : 'iconfont'">&#xe603;</i>
                        </div>
                    </div>
                    <div class="xlTableHeaderItem">操作</div>
                </div>
                <div class="xlTableBox" v-for="(item,index) in tableData2" :key="index">
                    <div class="xlTableBody">
                        <div class="xlTableHeaderItem">
                            <i @click="unfoldClose(item,index)" class="iconfont">{{item.isShow === true ? '&#xe633;' : '&#xec86;'}}</i>
                            <img v-if="item.user_info.headimgurl" :src="item.user_info.headimgurl.src" alt="">
                            <img v-if="!item.user_info.headimgurl" :src="require('./../../assets/images/user_img.png')"
                                 alt="">
                            <span>{{item.user_info.name}}</span>
                        </div>
                        <div class="xlTableHeaderItem">训练次数({{item.xlCount}}次)</div>
                        <div class="xlTableHeaderItem">平均分值({{item.avg_kf_score}})</div>
                        <div class="xlTableHeaderItem">平均分值({{item.avg_op_score}})</div>
                        <div class="xlTableHeaderItem">平均分值({{item.avg_nkf_score}})</div>
                        <div class="xlTableHeaderItem"></div>
                    </div>
                    <div class="xlTableFooter" v-if="item.isShow" v-for="(row,rowIndex) in item.row" :key="rowIndex">
                        <div class="xlTableFooterItem"></div>
                        <div class="xlTableFooterItem">第{{rowIndex+1}}次</div>
                        <div class="xlTableFooterItem">{{row.kf_score}}</div>
                        <div class="xlTableFooterItem">{{row.operation_score}}</div>
                        <div class="xlTableFooterItem">{{row.new_kefu_score}}</div>
                        <div class="xlTableFooterItem" @click="toDetail(row)">查看详情</div>
                    </div>
                </div>
            </div>
            <el-pagination
                    class="pages-center"
                    :current-page="adminPages2.currentPageNum"
                    :page-size="adminPages2.eachPageNum"
                    layout="prev, pager, next, jumper"
                    :total="adminPages2.total"
                    @current-change="adminCurrentChange2"
            ></el-pagination>
        </div>
    </el-scrollbar>
</template>

<script>
    import {getDataCenter, getComList} from "@/utils/apis"
    import {theoryTotalScoreCal} from "../../utils/apis";

    export default {
        name: "DataCenterScoreList",
        data() {
            return {
                isShow: true,
                selectClassValue: localStorage.getItem('selectValue')?Number(localStorage.getItem('selectValue')):'',
                selectClassValue2: localStorage.getItem('selectValue')?Number(localStorage.getItem('selectValue')):'',
                updateTime: '',
                classOptions: [],
                tableData: [],
                tableData2: [],
                adminPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                adminPages2: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                role: null,
                kfPercent: '',
                kfNPercent:'',
                marketingPercent: '',
                dianPu: '',
                keHu: '',
                newKeFu:'',
                yunYing: '',
                fenXi: '',
                dataAnalysis:'',
                topic: '',
                iconfontSortValue: 0,
                iconfontSortValue2: 0,
                iconfontSortValue3:0,
                selectClass2Val: '',
                selectClass2Val2: '',
                order: 'DESC',
            }
        },
        created() {
            let role = localStorage.getItem('role');
            this.role = role;
            this.getCollegeClass();
            this.acquisitionTime();
            if (this.role == 2) {
                this.selectClassValue2 && this.selectClass2();
                this.getXlDataList();
            } else {
                this.selectClassValue && this.selectClass();
                this.getDataList();
            }
        },
        beforeDestroy() {
            if(!(this.$route.name==='dataCenterDetail'||this.$route.name==='schooldataCenterDetail'||this.$route.name==='trainaDataCenterDetail')){
                localStorage.removeItem('selectValue')
            }
        },
        methods: {
            sorttTotalScore(column) {
                if (column.order === 'ascending') {
                    this.order = 'ASC';
                    if (this.role == 2) {
                        this.getXlDataList();
                    } else {
                        this.getDataList();
                    }
                }
                if (column.order === 'descending') {
                    this.order = 'DESC';
                    if (this.role == 2) {
                        this.getXlDataList();
                    } else {
                        this.getDataList();
                    }
                }

            },
            refreshTheoryScore() {
                if (!this.selectClassValue) {
                    this.$message.warning('请选择一场竞赛');
                    return;
                }
                this.$http.asyncGet(this.$api.theory_cal + this.selectClassValue, {}).then(res => {
                    if (res.code === 200) {
                        this.$message({
                            type: 'success',
                            message: res.msg,
                            duration: 1000,
                            onClose: () => {
                                Number(this.role) === 2 ? this.getXlDataList() : this.getDataList()
                            }
                        });
                    } else {
                        this.$message.error(res.msg)
                    }
                }).catch(err => {
                    console.log('err', err)
                })

            },
            refreshTotalScore() {
                if (!this.selectClassValue) {
                    this.$message.warning('请选择一场竞赛');
                    return;
                }
                this.$http.asyncGet(this.$api.data_refresh, {competition_id: this.selectClassValue}).then(res => {
                    if (res.code === 200) {
                        this.$message({
                            type: 'success',
                            message: res.msg,
                            duration: 1000,
                            onClose: () => {
                                if (this.role == 2) {
                                    this.getXlDataList();
                                } else {
                                    this.getDataList();
                                }
                            }
                        });
                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg,
                            duration: 1500
                        });
                    }
                }).catch(err => {
                    console.log(err);
                    this.$message.error('服务器出错了');
                });

            },
            exportTotalScore() {
                if (!this.selectClassValue) {
                    this.$message.warning('请选择一场竞赛');
                    return;
                }
                this.$http.asyncGet(this.$api.data_export, {competition_id: this.selectClassValue}).then(res => {
                    if (res.code === 200) {
                        window.open(res.data.data);
                    } else {
                        this.$message({
                            type: 'error',
                            message: res.msg,
                            duration: 1000
                        });
                    }
                }).catch(err => {
                    console.log(err);
                    this.$message.error('服务器出错了');
                })
            },
            kfSortMaxToMin() {
                this.iconfontSortValue2 = 0;
                if (this.iconfontSortValue != 1) {
                    this.iconfontSortValue = 1;
                    let param = {
                        page: this.adminPages2.currentPageNum,
                        limit: this.adminPages2.eachPageNum,
                        rank: 1
                    }
                    if (this.selectClassValue2) {
                        param.com_id = this.selectClassValue2
                    }
                    this.$http.axiosGetBy(this.$api.xlDataList, param, res => {
                        if (res.code === 200) {
                            this.tableData2 = res.data.data;
                            this.adminPages2.total = res.data.total;
                            if (this.tableData2.length) {
                                this.tableData2.map(item => {
                                    this.$set(item, 'isShow', false);
                                    this.$set(item, 'row', []);
                                })
                            }
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })

                } else {
                    this.iconfontSortValue = 0;
                    this.getXlDataList();
                }
            },
            kfSortMinToMax() {
                this.iconfontSortValue2 = 0;
                if (this.iconfontSortValue != 2) {
                    this.iconfontSortValue = 2
                    let param = {
                        page: this.adminPages2.currentPageNum,
                        limit: this.adminPages2.eachPageNum,
                        rank: 2
                    }
                    if (this.selectClassValue2) {
                        param.com_id = this.selectClassValue2
                    }
                    this.$http.axiosGetBy(this.$api.xlDataList, param, res => {
                        if (res.code === 200) {
                            this.tableData2 = res.data.data;
                            this.adminPages2.total = res.data.total;
                            if (this.tableData2.length) {
                                this.tableData2.map(item => {
                                    this.$set(item, 'isShow', false);
                                    this.$set(item, 'row', []);
                                })
                            }
                        } else {
                            this.$message.warning(res.msg)
                        }
                    });

                } else {
                    this.iconfontSortValue = 0
                    this.getXlDataList();


                }
            },
            marketingSortMinToMax() {
                this.iconfontSortValue = 0;
                if (this.iconfontSortValue2 != 1) {
                    this.iconfontSortValue2 = 1
                    let param = {
                        page: this.adminPages2.currentPageNum,
                        limit: this.adminPages2.eachPageNum,
                        rank: 3
                    }
                    if (this.selectClassValue2) {
                        param.com_id = this.selectClassValue2
                    }
                    this.$http.axiosGetBy(this.$api.xlDataList, param, res => {
                        if (res.code === 200) {
                            this.tableData2 = res.data.data;
                            this.adminPages2.total = res.data.total;
                            if (this.tableData2.length) {
                                this.tableData2.map(item => {
                                    this.$set(item, 'isShow', false);
                                    this.$set(item, 'row', []);
                                })
                            }
                        } else {
                            this.$message.warning(res.msg)
                        }
                    });

                } else {
                    this.iconfontSortValue2 = 0
                    this.getXlDataList();

                }
            },
            marketingSortMaxToMin() {
                this.iconfontSortValue = 0;
                if (this.iconfontSortValue2 != 2) {
                    this.iconfontSortValue2 = 2
                    let param = {
                        page: this.adminPages2.currentPageNum,
                        limit: this.adminPages2.eachPageNum,
                        rank: 4
                    }
                    if (this.selectClassValue2) {
                        param.com_id = this.selectClassValue2
                    }
                    this.$http.axiosGetBy(this.$api.xlDataList, param, res => {
                        if (res.code === 200) {
                            this.tableData2 = res.data.data;
                            this.adminPages2.total = res.data.total;
                            if (this.tableData2.length) {
                                this.tableData2.map(item => {
                                    this.$set(item, 'isShow', false);
                                    this.$set(item, 'row', []);
                                })
                            }
                        } else {
                            this.$message.warning(res.msg)
                        }
                    });
                } else {
                    this.iconfontSortValue2 = 0
                    this.getXlDataList();
                }
            },
            newKFSortMinToMax() {
                this.iconfontSortValue = 0;
                if (this.iconfontSortValue3 != 1) {
                    this.iconfontSortValue3 = 1
                    let param = {
                        page: this.adminPages2.currentPageNum,
                        limit: this.adminPages2.eachPageNum,
                        rank: 6
                    }
                    if (this.selectClassValue2) {
                        param.com_id = this.selectClassValue2
                    }
                    this.$http.axiosGetBy(this.$api.xlDataList, param, res => {
                        if (res.code === 200) {
                            this.tableData2 = res.data.data;
                            this.adminPages2.total = res.data.total;
                            if (this.tableData2.length) {
                                this.tableData2.map(item => {
                                    this.$set(item, 'isShow', false);
                                    this.$set(item, 'row', []);
                                })
                            }
                        } else {
                            this.$message.warning(res.msg)
                        }
                    });

                } else {
                    this.iconfontSortValue3 = 0
                    this.getXlDataList();

                }
            },
            newKFSortMaxToMin() {
                this.iconfontSortValue = 0;
                if (this.iconfontSortValue3 != 2) {
                    this.iconfontSortValue3 = 2
                    let param = {
                        page: this.adminPages2.currentPageNum,
                        limit: this.adminPages2.eachPageNum,
                        rank: 5
                    }
                    if (this.selectClassValue2) {
                        param.com_id = this.selectClassValue2
                    }
                    this.$http.axiosGetBy(this.$api.xlDataList, param, res => {
                        if (res.code === 200) {
                            this.tableData2 = res.data.data;
                            this.adminPages2.total = res.data.total;
                            if (this.tableData2.length) {
                                this.tableData2.map(item => {
                                    this.$set(item, 'isShow', false);
                                    this.$set(item, 'row', []);
                                })
                            }
                        } else {
                            this.$message.warning(res.msg)
                        }
                    });
                } else {
                    this.iconfontSortValue3 = 0
                    this.getXlDataList();
                }
            },
            getDataList() {
                if (this.role != 2) {
                    let param = {
                        type: 'page',
                        page: this.adminPages.currentPageNum,
                        limit: this.adminPages.eachPageNum,
                        order: this.order
                    }
                    if (this.selectClassValue) {
                        param.competition_id = this.selectClassValue;
                    }
                    getDataCenter(param).then(res => {
                        if (res.code === 200) {
                            this.tableData = res.data.data;
                            this.adminPages.total = res.data.total;
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                }
            },
            // 训练列表
            getXlDataList() {
                if (this.role == 2) {
                    let param = {
                        page: this.adminPages2.currentPageNum,
                        limit: this.adminPages2.eachPageNum,
                        order: this.order
                    };
                    if (this.selectClassValue2) {
                        param.com_id = this.selectClassValue2
                    }
                    if (this.iconfontSortValue === 1) {
                        param.rank = 1
                    } else if (this.iconfontSortValue === 2) {
                        param.rank = 2
                    } else if (this.iconfontSortValue2 === 1) {
                        param.rank = 3
                    } else if (this.iconfontSortValue2 === 2) {
                        param.rank = 4
                    }else if(this.iconfontSortValue3===1){
                        param.rank=6
                    }else if(this.iconfontSortValue3===2){
                        param.rank=5
                    }
                    this.$http.axiosGetBy(this.$api.xlDataList, param, res => {
                        if (res.code === 200) {
                            this.tableData2 = res.data.data;
                            this.adminPages2.total = res.data.total;
                            if (this.tableData2.length) {
                                this.tableData2.map(item => {
                                    this.$set(item, 'isShow', false);
                                    this.$set(item, 'row', []);
                                })
                            }
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                }
            },
            // 展开
            unfoldClose(item, index) {
                console.log(item)
                this.tableData2[index].isShow = !this.tableData2[index].isShow;
                if (this.tableData2[index].isShow) {
                    let param = {
                        type: 'all',
                        stu_id: item.user_id,
                        com_id: item.competition_id
                    }
                    this.$http.axiosGetBy(this.$api.getOneStuData, param, res => {
                        if (res.code === 200) {
                            this.tableData2[index].row = res.data;
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                }
            },
            getCollegeClass() {
                let param = {};
                param.mode = this.role == 2 ? 2 : 1
                getComList(param).then(res => {
                    if (res.code === 200) {
                        this.classOptions = res.data;
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            dateFormatTwo: function (time) {
                var date = new Date(time);
                var year = date.getFullYear();
                var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
                var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
                var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
                var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
                var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
                // 拼接
                return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
            },
            adminCurrentChange(val) {
                this.adminPages.currentPageNum = val;
                this.getDataList()
            },
            adminCurrentChange2(val) {
                this.adminPages2.currentPageNum = val;
                this.getXlDataList(this.selectClass2Val)
            },
            acquisitionTime() {
                let time = new Date();
                this.updateTime = this.dateFormatTwo(time);
            },
            refreshPage() {
                location.reload()
            },
            selectClass(val = this.selectClassValue) {
                console.log(typeof val)
                localStorage.setItem('selectValue',this.selectClassValue)
                // this.selectClassValue = val;
                this.adminPages.currentPageNum = 1;
                this.dianPu = '';
                this.keHu = '';
                this.yunYing = '';
                this.newKeFu="";
                this.fenXi = '';
                this.dataAnalysis='';
                this.topic = '';
                if (val) {
                    this.$http.axiosGetBy(this.$api.getCom, {com_id: val}, res => {
                        if (res.code === 200) {
                            this.$http.axiosGetBy(this.$api.template, {id: res.data.tmp_id}, tmpRes => {
                                if (tmpRes.code === 200) {
                                    let expand = tmpRes.data.expand;
                                    expand.map(item => {
                                        if (item.tmpl_module_type === 1) {
                                            this.dianPu = item.tmpl_module_percent;
                                        } else if (item.tmpl_module_type === 2) {
                                            this.yunYing = item.tmpl_module_percent;
                                        } else if (item.tmpl_module_type === 3) {
                                            this.keHu = item.tmpl_module_percent
                                        } else if (item.tmpl_module_type === 4) {
                                            this.fenXi = item.tmpl_module_percent
                                        } else if (item.tmpl_module_type === 5) {
                                            this.topic = item.tmpl_module_percent
                                        }else if(item.tmpl_module_type===7){
                                            this.newKeFu=item.tmpl_module_percent
                                        }else if(item.tmpl_module_type===6){
                                            this.dataAnalysis=item.tmpl_module_percent
                                        }
                                    })
                                } else {
                                    this.$message.warning(tmpRes.msg)
                                }
                            })
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                }
                this.getDataList();
            },
            selectClass2(val = this.selectClassValue2) {
                localStorage.setItem('selectValue',this.selectClassValue2)
                // this.selectClassValue2 = val;
                this.adminPages2.currentPageNum = 1;
                this.kfPercent = '';
                this.kfNPercent='';
                this.marketingPercent = '';
                if (val) {
                    this.$http.axiosGetBy(this.$api.getCom, {com_id: val}, res => {
                        if (res.code === 200) {
                            this.$http.axiosGetBy(this.$api.template, {id: res.data.tmp_id}, tmpRes => {
                                if (tmpRes.code === 200) {
                                    let expand = tmpRes.data.expand;
                                    expand.map(item => {
                                        if (item.tmpl_module_type === 3) {
                                            this.kfPercent = item.tmpl_module_percent;
                                        } else if (item.tmpl_module_type === 2) {
                                            this.marketingPercent = item.tmpl_module_percent;
                                        }else if(item.tmpl_module_type===7){
                                            this.kfNPercent=item.tmpl_module_percent;
                                        }
                                    })
                                } else {
                                    this.$message.warning(tmpRes.msg)
                                }
                            })
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                }
                this.getXlDataList();
            },
            toDetail(row) {
                if (this.$route.name === 'schoolAdminDataCenter') {
                    this.$router.push({
                        path: '/schooladmin/datacenterdetail',
                        query: {
                            id: row.user_id,
                            cId: row.competition_id, // 比赛id
                            rId: row.id           
                        }
                    })
                } else if (this.$route.name === 'dataCenter') {
                    this.$router.push({
                        path: '/admin/datacenterdetail',
                        query: {
                            id: row.user_id,
                            cId: row.competition_id, // 比赛id
                            rId: row.id
                        }
                    })
                } else if (this.$route.name === 'DataCenterScoreList') {
                    this.$router.push({
                        path: '/trainadmin/datacenterdetail',
                        query: {
                            id: row.user_id,
                            cId: row.competition_id, // 比赛id
                            sort: row.sort,
                            xlId: row.id, // 训练id
                            xlrzid: row.new_kefu_log_id,//客服训练日志id
                        }
                    })
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .header-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .button-space {
            margin-left: 10px;
        }
    }

    .iconfontSort {
        color: #409eff;
    }

    .roleNot2 {
        ::v-deep th {
            padding: 0 !important;
            height: 45px;
            line-height: 45px;
        }

        ::v-deep td {
            padding: 0 !important;
            height: 45px;
            line-height: 45px;
        }
    }

    ::v-deep .el-table--border::after, .el-table--group::after, .el-table::before {
        display: none;
    }

    ::v-deep .caret-wrapper .ascending {
        border-bottom-color: #14141C
    }

    ::v-deep .caret-wrapper .descending {
        border-top-color: #14141C
    }

    ::v-deep .pages-center {
        display: flex;
        justify-content: center;
        margin-top: 24px;
    }

    ::v-deep.el-select {
        width: 140px;
    }

    .look-detail {
        color: #0200D8;

        &:hover {
            color: #5864e4;
        }
    }

    .rankImg {
        background: url("./../../assets/images/dataCenter/one.png") no-repeat center center;
    }

    .rankImgTwo {
        background: url("./../../assets/images/dataCenter/two.png") no-repeat center center;
    }

    .rankImgThree {
        background: url("./../../assets/images/dataCenter/three.png") no-repeat center center;
    }

    .operation-detail {
        /*margin: 0 auto;*/
        width: 30px;
        height: 30px;
        display: inline-block;
        font-size: 18px;
        color: #ffffff;
        background: #0200D8;
        text-align: center;
        border-radius: 50%;
        line-height: 30px;
    }

    .scoreList {
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;

            .el-scrollbar__view {
                padding: 30px;
            }
        }

        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        /*overflow: hidden;*/
        .scoreList-header {
            color: #14141C;
            font-size: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .header-right {
                color: #343442;

                i {
                    margin-left: 10px;
                    font-size: 14px;
                }
            }
        }
    }

    .box {
        .xlTable {
            height: calc(100vh - 300px);
            overflow-y: auto;
            border: 1px solid #EEEEEE;
            margin-top: 20px;
            font-size: 14px;

            .xlTableHeader {
                display: flex;
                height: 60px;
                background: #F3F4FA;
                line-height: 60px;
                color: #14141C;
                text-align: center;

                .xlTableHeaderItem {
                    width: 25%;
                }
            }

            .xlTableBody {
                display: flex;

                .xlTableHeaderItem:nth-child(1) {
                    justify-content: left;

                    i {
                        margin-left: 30px;
                    }
                }

                .xlTableHeaderItem {
                    border-bottom: 1px solid #EEEEEE;
                    width: 25%;
                    height: 60px;
                    line-height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    i {
                        font-size: 12px;
                        margin-right: 10px;
                        cursor: pointer;
                    }

                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        margin-right: 10px;
                    }
                }
            }

            .xlTableFooter {
                display: flex;

                .xlTableFooterItem {
                    border-bottom: 1px solid #EEEEEE;
                    width: 25%;
                    height: 60px;
                    line-height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .xlTableFooterItem:nth-child(6) {
                    color: #1222D8;
                    cursor: pointer;
                }
            }
        }
    }
</style>